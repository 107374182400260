<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-tabs v-model="tabs" class="my-tabs" @change="tab_change">
          <v-tab> Agents </v-tab>
          <v-tab> Structure </v-tab>
          <v-tab> Produit </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" sm="6" md="1">
        <datepicker
          label="Date Debut"
          :edit="true"
          :clearable="false"
          v-model="exercice.dd"
        ></datepicker>
      </v-col>
      <v-col cols="12" sm="6" md="1">
        <datepicker
          label="Date Fin"
          :edit="true"
          :clearable="false"
          v-model="exercice.df"
        ></datepicker>
      </v-col>
      <v-col cols="12" sm="6" md="1">
        <v-btn dark color="indigo" class="mt-4" @click="reload">
          <v-icon left dark> mdi-cloud-download </v-icon>
          Recharger
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="tabs == 0">
      <v-col cols="12" sm="5" md="5">
        <listitems
          :list="agents_list"
          :title="'Agents'"
          :headers="agents_headers"
          :field="'CREATED_AT'"
          :filename="'Agents'"
          :sheetname="'Agent'"
          :key="klist"
          @rowselect="AgentChange"
          :add="false"
          :Update="false"
          :del="false"
          :laoding="loading"
        >
        </listitems>
      </v-col>
      <v-col cols="12" sm="7" md="7">
        <listitems
          :list="mvms_list"
          :title="'Mouvements'"
          :headers="mvms_headers"
          :field="'CREATED_AT'"
          :filename="'Mouvements'"
          :sheetname="'Mouvements'"
          :key="kart"
          :add="false"
          :Update="false"
          :del="false"
          :print="true"
          @print="print_cons('a')"
          :laoding="art_loading"
          :Total="true"
        >
        </listitems>
      </v-col>
    </v-row>
    <v-row v-if="tabs == 1">
      <v-col cols="12" sm="5" md="5">
        <v-treeview
          :active.sync="active"
          v-model="active"
          :items="struct_list"
          :item-key="'id'"
          :item-text="'label'"
          activatable
          color="primary"
          @update:active="onActive"
          transition
          :return-object="true"
          selection-type="independent"
          :key="kt"
        >
          <template v-slot:label="{ item }">
            <v-chip :color="item.color">
              {{ item.prop_name ? item.prop_name + ":" : "" }}
              {{ item.label }}
            </v-chip>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="12" sm="7" md="7">
        <listitems
          :list="mvms_list"
          :title="'Mouvements'"
          :headers="mvms_headers"
          :field="'CREATED_AT'"
          :filename="'Mouvements'"
          :sheetname="'Mouvements'"
          :key="kart"
          :add="false"
          :Update="false"
          :del="false"
          :Total="true"
          :print="true"
          @print="print_cons('s')"
          :laoding="art_loading"
        >
        </listitems>
      </v-col>
    </v-row>
    <v-row v-if="tabs == 2">
      <v-col cols="12" sm="6" md="6">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'products'"
          :AllResponse="'allproducts'"
          :title="'Liste des Produits'"
          :headers="product_headers"
          :Kind="34"
          :field="'CODE'"
          :filename="'Produits'"
          :sheetname="'Produits'"
          @rowselect="ProductChange"
          :showedit="false"
          :key="kp"
          :Add="false"
          :del="false"
          :expire1="2"
          :Total="true"
          :list_options="false"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <listitems
          :list="mvms_list"
          :title="'Employés'"
          :headers="agents2_headers"
          :field="'CREATED_AT'"
          :filename="'Employés'"
          :sheetname="'Employés'"
          :key="kart"
          :add="false"
          :Update="false"
          :del="false"
          :Total="true"
          :laoding="art_loading"
        >
        </listitems>
      </v-col>
    </v-row>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    node.index = i;
    if (node.fk_parent !== "0") {
      // if you have dangling branches check that map[node.parentId] exists
      if (list[map[node.fk_parent]])
        list[map[node.fk_parent]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
import MVMS_DETAIL from "../graphql/Mouvment/MVMS_DETAIL.gql";
import CONSOM_DATA from "../graphql/Stat/CONSOM_DATA.gql";
import PRODUCTS from "../graphql/Product/PRODUCTS.gql";
import ALLPRODUCTS from "../graphql/Product/ALLPRODUCTS.gql";
import { datefr } from "@/utils/functions.js";
import { doc_template } from "print/template.js";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    listitemspage: () => import("../components/ListItemsPages.vue"),
  },

  data: () => ({
    loading: false,
    kt: 100,
    kp: 200,
    klist: 400,
    kart: 500,
    agent: {},
    struct: {},
    article: {},
    selitem: {},
    product: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    exercice: {},
    active: [],

    product_headers: [
      {
        text: "Code",
        value: "code",
        enum: "CODE",
        selected: true,
      },
      {
        text: "Désignation",
        value: "label",
        enum: "LABEL",
        selected: true,
      },
      {
        text: "Stock",
        value: "stock_cmpt",
        enum: "STOCK_CMPT",
        align: "end",
        width: "150px",
        selected: true,
        slot: "cur",
      },
      {
        text: "Statut",
        value: "statuts_name",
        enum: "STATUTS_NAME",
        selected: false,
        slot: "chip",
        color: "color",
      },
    ],
    agents_headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Matricule",
        value: "matricule",
        selected: true,
      },
      {
        text: "Nom",
        value: "nom",
        selected: true,
      },
      {
        text: "Fonction",
        value: "fonction",
        selected: true,
      },
      {
        text: "Structure",
        value: "structure",
        selected: true,
      },
    ],
    agents2_headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Ref",
        value: "nobon",
        selected: true,
      },
      {
        text: "Date",
        value: "mvm_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Matricule",
        value: "matricule",
        selected: true,
      },
      {
        text: "Nom",
        value: "nom",
        selected: true,
      },
      {
        text: "Entree",
        value: "entree",
        slot: "cur33",
        total: "entree",
        selected: true,
      },
      {
        text: "Sortie",
        value: "sortie",
        slot: "cur33",
        total: "sortie",
        selected: true,
      },

      {
        text: "pu",
        value: "pu",
        slot: "cur",
        selected: false,
      },
      {
        text: "Valeur",
        value: "ht",
        slot: "cur",
        selected: false,
      },
      {
        text: "Fonction",
        value: "fonction",
        selected: false,
      },
      {
        text: "Structure",
        value: "structure",
        selected: false,
      },
    ],
    struct_headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },

      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Structure",
        value: "structure",
        selected: true,
      },
      {
        text: "Nn Agent",
        value: "agentcount",
        selected: true,
      },
    ],
    mvms_headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Ref",
        value: "nobon",
        selected: true,
      },
      {
        text: "Date",
        value: "mvm_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Designation",
        value: "label",
        selected: true,
      },
      {
        text: "Entree",
        value: "entree",
        slot: "cur33",
        total: "entree",
        selected: true,
      },
      {
        text: "Sortie",
        value: "sortie",
        slot: "cur33",
        total: "sortie",
        selected: true,
      },
      {
        text: "Unité",
        value: "unit",
        selected: false,
      },
      {
        text: "Colis",
        value: "coliscount",
        slot: "cur33",
        total: "coliscount",
        selected: true,
      },
    ],
    agents_list: [],
    articles_list: [],
    mvms_list: [],
    struct_list: [],
    fonctions: [],
    structures: [],
    art_loading: false,
    tabs: "0",
  }),
  watch: {},
  computed: {
    Qselect() {
      return PRODUCTS;
    },
    Qselectall() {
      return ALLPRODUCTS;
    },
  },
  created() {},

  mounted() {
    this.get_data();
    this.exercice.dd = this.$store.state.exercice.toString() + "-01-01";
    this.exercice.df = this.$store.state.today;
  },

  methods: {
    tab_change() {
      this.mvms_list = [];
    },
    print_cons(e) {
      let doctype = [];
      doctype.push({
        lib: {
          content: "Etat des Mouvements ",
          styles: {
            fontStyle: "bold",
            fontSize: 20,
            textColor: [0, 0, 0],
            cellPadding: { bottom: 3 },
          },
          colSpan: 2,
        },
      });
      doctype.push({
        lib: {
          content: "Du",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(this.exercice.dd),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Au : ",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(this.exercice.df),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });

      let tier = [];
      tier.push({
        lib: {
          content: e == "a" ? "Monsieur :" : "Structure :",
          styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: e == "a" ? this.agent.nom : this.struct.label,
          styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: e == "a" ? "Matricule" : "Code",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content:
            e == "a"
              ? this.agent.matricule
                ? this.agent.matricule
                : ""
              : this.struct.code
              ? this.struct.code
              : "",
          styles: {
            fontStyle: "normal",
            fontSize: 9,
            textColor: [0, 0, 0],
          },
        },
      });
      let lettre = [];
      if (e == "s")
        lettre.push({
          libelle:
            "Responsable : " +
            (this.struct.responsable ? this.struct.responsable : ""),
        });
      doc_template(
        this.$store,
        this.mvms_headers,
        this.mvms_list,
        doctype,
        tier,
        null,
        lettre,
        null,
        "Etat des Mouvements",
        null,
        null,
        null,
        null,
        "p"
      );
    },
    reload() {
      if (this.tabs == "0") {
        this.AgentChange(this.agent);
      }
      if (this.tabs == "1") {
        this.onActive(this.struct);
      }
      if (this.tabs == "2") {
        this.ProductChange(this.product);
      }
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },

    async get_data() {
      this.loading = true;
      let r = await this.requette(CONSOM_DATA);
      if (r) {
        this.agents_list = r.agents.sort(this.sortBy("nom"));
        this.struct_list = list_to_tree(
          r.structures.filter((elm) => elm.type_id != 2)
        );
        this.klist++;
        this.kt++;
      }
    },
    async onActive() {
      this.mvms_list = [];
      this.struct = this.active[0];
      if (this.struct)
        if (this.struct.id) {
          let v = {
            date_debut: this.exercice.dd,
            date_fin: this.exercice.df,
            struct_id: this.struct.id,
          };
          this.art_loading = true;
          let r = await this.requette(MVMS_DETAIL, v);
          this.art_loading = false;
          if (r) this.mvms_list = r.mvms_detail;
          this.kart++;
        }
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async ProductChange(item) {
      this.mvms_list = [];
      this.product = item;
      this.selitem = item;
      if (this.product.id) {
        let v = {
          date_debut: this.exercice.dd,
          date_fin: this.exercice.df,
          produit_id: this.product.id,
        };
        this.art_loading = true;
        let r = await this.requette(MVMS_DETAIL, v);
        this.art_loading = false;
        if (r) this.mvms_list = r.mvms_detail;
        this.kart++;
      }
    },
    async AgentChange(item) {
      this.mvms_list = [];
      this.agent = item;
      if (this.agent.id) {
        let v = {
          date_debut: this.exercice.dd,
          date_fin: this.exercice.df,
          agent_id: this.agent.id,
        };
        this.art_loading = true;
        let r = await this.requette(MVMS_DETAIL, v);
        this.art_loading = false;
        if (r) this.mvms_list = r.mvms_detail;
        this.kart++;
      }
    },
  },
};
</script>
